<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
          <!-- 搜索、导出按钮 -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="exportExcel"
                  class="mr-1"
              >
                <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="selectAllRows"
                  class="mr-1"
              >
                <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                />
                <span class="align-middle">全部勾选</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="fix"
                  class="mr-1"
              >
                <feather-icon
                    icon="ToolIcon"
                    class="mr-50"
                />
                <span class="align-middle">批量修正</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  @click="clearSelected"
                  class="mr-1"
              >
                <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                />
                <span class="align-middle">清空勾选</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>

              <!--仓库名称-->
              <b-col md="3">
                <modal-select
                    label="仓库名称"
                    type="input"
                    v-on:change="fromChildren($event,['warehouse_name','warehouse_id'],condition)"
                    :params="['warehouse_name','warehouse_id']"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                    v-model="condition.warehouse_name"
                >
                </modal-select>
              </b-col>
              <!--商品名称-->
              <b-col md="3">
                <modal-select
                    label="商品名称"
                    type="input"
                    v-on:change="fromChildren($event,['product_name','product_id'],condition)"
                    :params="['product_name','product_id']"
                    modalName="商品"
                    placeholder="点击选择商品名称"
                    v-model="condition.product_name"
                >
                </modal-select>
              </b-col>
              <!--添加时间-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="添加时间"
                    label-for="time"
                    label-size="sm"
                >
                  <flat-pickr
                      v-model="condition.time"
                      class="form-control"
                      :config="rangeConfig"
                      placeholder="请选择添加时间"
                  />
                </b-form-group>
              </b-col>
              <!--是否已修正-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否已修正"
                    label-for="status"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('yesno')"
                      v-model="condition.is_change"
                      class="select-size-sm"
                      :reduce="option => option.value"
                      placeholder="请选择是否已修正"
                  />
                </b-form-group>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          selectable
          selected-variant="info"
          select-mode="multi"
          @row-selected="onRowSelected"
      >

        <!-- Columns -->
        <template #cell(history_id)="data">
          #{{ data.item.id }}
        </template>

        <template #cell(is_change)="data">
          <b-badge pill :variant="`light-${getCodeColor('yesno', data.item.is_change)}`">
            {{ getCodeLabel('yesno', data.item.is_change) }}
          </b-badge>
        </template>

        <!--添加时间-->
        <template #cell(add_time)="data">
          {{ toTime(data.item.add_time) }}
        </template>

        <!--修正时间-->
        <template #cell(change_time)="data">
          {{ toTime(data.item.change_time) }}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import stockcheckhistoryUseList from './stockcheckhistoryUseList'
import stockcheckhistoryStore from './stockcheckhistoryStore'
import Ripple from "vue-ripple-directive";
import ModalSelect from "@/views/components/modal/ModalSelect";
import axios from "@/libs/axios";
import {useToast} from "vue-toastification/composition";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return{
      isShowCard: false,
      rangeConfig: {
        maxDate: 'today',
      },
      selected: [],
      user: {},
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('stockcheckhistory')) store.registerModule('stockcheckhistory', stockcheckhistoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockcheckhistory')) store.unregisterModule('stockcheckhistory')
    })

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData();
    }

    const exportExcel = function () {
      const params = {
        warehouse_id: condition.value.warehouse_id,
        product_id: condition.value.product_id,
        time: condition.value.time,
        is_change: condition.value.is_change
      }
      axios({
        url: '/api/exportexcelfile/exportStockcheckHistory',
        method: 'get',
        params: params,
        responseType: 'blob',
      }).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = '库存修正' + new Date().getTime()
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const fromChildren = function (params, modal, item) {
      for (let i = 0; i < modal.length; i++) {
        item[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const onRowSelected = function (items) {
      this.selected = items
    }

    const selectAllRows = function () {
      this.$refs.refListTable.selectAllRows()
    }

    const clearSelected = function () {
      this.$refs.refListTable.clearSelected()
    }

    const fix = function () {
      if(this.user.user_id !== 2){
        toast.error('无权限')
        return false
      }
      if (this.selected.length < 1) {
        toast.error('至少选中一条')
        return false
      }
      let ids = [];
      for (let i = 0; i < this.selected.length; i++) {
        ids.push(this.selected[i].id)
      }
      axios.post('/api/stockcheckhistory/fix', {ids: ids.toString()}).then(res => {
        if (res.data.code == 0) {
          //弹窗
          this.$swal({
            icon: 'success',
            title: '已提交!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      // UI
    } = stockcheckhistoryUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      advanced_search,
      searchByCondition,
      resetCondition,
      exportExcel,
      fromChildren,
      onRowSelected,
      selectAllRows,
      clearSelected,
      fix,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
