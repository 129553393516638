import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function stockcheckhistoryUseList() {
  // Use toast
  const toast = useToast()
  const condition = ref({})
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'history_id', label: 'ID', sortable: true },
    { key: 'add_time', label: '添加时间', sortable: true },
    { key: 'warehouse_name', label: '仓库名称', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stock_id', label: 'stock_id', sortable: true },
    { key: 'change_value', label: '调整值', sortable: true },
    { key: 'is_change', label: '是否已修正', sortable: true },
    { key: 'change_time', label: '修正时间', sortable: true },
  ]

  const start = ref(1)
  const limit = ref(100)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('history_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('stockcheckhistory/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        warehouse_id: condition.value.warehouse_id,
        product_id: condition.value.product_id,
        time: condition.value.time,
        is_change: condition.value.is_change
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    condition,
    refetchData,

    // Extra Filters
  }
}
